import Vue from 'vue';
import { defineStore } from 'pinia';
import { CalloutsService } from '@watchtowerbenefits/shared-components';

const {
  readCallouts,
  createCallout,
  patchCalloutRead,
} = CalloutsService;

export const useCallouts = defineStore('callouts', {
  state: () => ({
    callouts: [],
    calloutMap: {},
    isLoaded: false,
  }),

  getters: {
    getSingleCallout: (state) => (pdvId) => state.callouts.find(
      (callout) => callout.plan_design_value_id === pdvId,
    ),
  },

  actions: {
    /**
     * Resets the callout store.
     *
     * @returns {void}
     */
    resetCallouts() {
      this.$reset();
    },
    /**
     * Load all callouts for the provided product ids, callouts
     * will be stored in a map by plan design value id.
     *
     * @param {Array<number>} productIds
     * @returns {Promise<Array|object>} Resolves to an array of callouts or a map of callouts by plan design value id.
     */
    async loadCallouts(productIds) {
      this.$reset();

      try {
        // eslint-disable-next-line no-restricted-syntax
        for await (const productId of productIds) {
          this.calloutMap = { ...this.calloutMap, ...await readCallouts(productId) };
        }
      } catch (error) {
        throw new Error('There was an error when loading callouts.');
      } finally {
        this.isLoaded = true;
      }

      return this.calloutMap;
    },
    /**
     * Create a new callout and add it to the store, callouts
     * will be stored in a map by plan design value id.
     *
     * @param {number} productId
     * @param {number} planDesignValueId
     * @param {string} note
     */
    async addCallout(productId, planDesignValueId, note) {
      try {
        const newCallout = await createCallout(productId, planDesignValueId, note);

        if (!this.calloutMap[newCallout.plan_design_value_id]) {
          Vue.set(this.calloutMap, newCallout.plan_design_value_id, []);
        }

        this.calloutMap[newCallout.plan_design_value_id].push(newCallout);
      } catch {
        throw new Error('There was an error when creating a callout.');
      }
    },
    /**
     * Mark a callout as read in the API and update the callout in the store.
     *
     * @param {number} calloutId
     * @param {number} planDesignValueId - if provided, the callout will be updated in the calloutMap.
     */
    async markCalloutAsRead(calloutId, planDesignValueId) {
      const updatedCallout = await patchCalloutRead(calloutId);

      if (planDesignValueId !== undefined) {
        const calloutIndex = this.calloutMap[planDesignValueId]
          .findIndex(({ id }) => id === calloutId);

        Vue.set(this.calloutMap[planDesignValueId], calloutIndex, updatedCallout);
      } else {
        const calloutIndex = this.callouts
          .findIndex(({ id }) => id === calloutId);

        Vue.set(this.callouts, calloutIndex, updatedCallout);
      }
    },
  },
});

export default {
  useCallouts,
};
