/**
 * Define default values for Feature Flags as a fallback in case Launch Darkly is down or a flag cannot be found
 * Feature Flags are kebab-cased keys by default in Launch Darkly but Vue-LD converts them to camelCased keys
 * therefore the Feature Flag values below should be camelCased
 * See this issue for details https://github.com/dashhudson/vue-ld/issues/22
 *
 * @exports utils/featureFlags
 */
// This flag will likely be needed in LD permanently unless the BE does some work to serve the id values to us
// Details: https://threeflow.slack.com/archives/C016A4VQZLH/p1737321016257139?thread_ts=1737155466.478759&cid=C016A4VQZLH
export const hideMarketActivityTab = 'hideMarketActivityTab';
// rff:statusFilterReorder https://watchtower.atlassian.net/browse/BPL-1862
export const statusFilterReorder = 'statusFilterReorder';
// rff:userDefaultCallouts https://watchtower.atlassian.net/browse/BPL-1863
export const userDefaultCallouts = 'userDefaultCallouts';
// rff:customTableSort https://watchtower.atlassian.net/browse/BPL-1865
export const customTableSort = 'customTableSort';
// rff:productTypesColumn https://watchtower.atlassian.net/browse/BPL-1871
export const productTypesColumn = 'productTypesColumn';
// rff: workOsCarrierUi https://watchtower.atlassian.net/browse/LC-2036
export const workOsCarrierUi = 'workOsCarrierUi';
// rff: hideEventsContactTab https://watchtower.atlassian.net/browse/BPL-4455
export const eventsContactTab = 'eventsContactTab';

// These defaults will be set instead of the defaultFlagValues if a user is in the dev or test environment
// this allows devs to manually control feature toggles while developing or testing
export const developmentFlagValues = {
  [hideMarketActivityTab]: '100,110,111,116,117,118,132', // ['100,110,111,116,117,118,132', 'none']',
  [statusFilterReorder]: true,
  [userDefaultCallouts]: true,
  [customTableSort]: true,
  [productTypesColumn]: true,
  [workOsCarrierUi]: true,
  [eventsContactTab]: true,
};

/**
 * Default Launch Darkly Flags (for Production, Staging and Features or if Launch Darkly can't be reached)
 * Keys match the feature flag names and values match the actual default flag values that we want to set.
 * We will need to update this object each time a new flag is added to Launch Darkly
 * and also be sure to use safe defaults here in case we cannot communicate with Launch Darkly so that
 * we do not accidentally enable a feature in production.
 */
export const defaultFlagValues = {
  [hideMarketActivityTab]: '100,110,111,116,117,118,132',
  [statusFilterReorder]: false,
  [userDefaultCallouts]: false,
  [customTableSort]: false,
  [productTypesColumn]: true,
  [workOsCarrierUi]: false,
  [eventsContactTab]: false,
};
